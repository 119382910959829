/** @jsx jsx */
import { InView } from "react-intersection-observer"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { jsx, Flex, Box, Heading } from "theme-ui"
import { motion } from "framer-motion"

import AguilaButton from "components/AguilaButton"
import BgImage from "components/BgImage"
import MdContent from "components/common/MdContent"

interface ImageTextSplitProps {
  //enables sx prop to be passed down from parent
  buttonText?: string
  buttonUrl?: string
  buttonVariant?: "primary" | "secondary" | "textOnly"
  className?: string
  content?: string
  fullImage?: boolean
  heading?: string
  image?: IGatsbyImageData
  imageAlt?: string
  normalImage?: string
  subHeading?: string
}

const ImageTextSplit = ({
  buttonText,
  buttonUrl,
  buttonVariant = "primary",
  className,
  content,
  fullImage,
  heading,
  image,
  imageAlt = "",
  normalImage,
  subHeading,
}: ImageTextSplitProps) => {
  return (
    <Flex
      className={className}
      as="section"
      sx={{
        backgroundColor: "lightTan",
        flexDirection: ["column", "row"],
      }}
    >
      <Flex
        sx={{
          flex: ["1 1 100%", "0 1 50%", null, null, null],
          background: [
            `linear-gradient(180deg, #D6D1C4 72%, #f5f3EC 72%)`,
            `linear-gradient(90deg, #D6D1C4 82%, #f5f3EC 82%)`,
            null,
            null,
            null,
          ],
          pl: fullImage ? 0 : 4,
          pr: fullImage ? 0 : [4, 0],
          justifyContent: "flex-end",
        }}
      >
        {!fullImage && image && !normalImage && (
          <Flex
            sx={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <GatsbyImage
              image={image}
              alt={imageAlt}
              sx={{ maxWidth: "770px", width: "100%" }}
            />
          </Flex>
        )}

        {!fullImage && !image && normalImage && (
          <Flex
            sx={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={normalImage}
              alt={imageAlt}
              sx={{ maxWidth: "770px", width: "100%" }}
            />
          </Flex>
        )}

        {fullImage && typeof image === "object" && !normalImage && (
          <Box
            sx={{ width: "100%", height: "auto", minHeight: ["300px", null] }}
          >
            <BgImage
              image={image}
              alt={imageAlt}
              sx={{ backgroundPosition: "right center" }}
            />
          </Box>
        )}

        {fullImage && !image && normalImage && (
          <Box
            sx={{ width: "100%", height: "auto", minHeight: ["300px", null] }}
          >
            <BgImage
              normalImage={normalImage}
              alt={imageAlt}
              sx={{ backgroundPosition: "right center" }}
            />
          </Box>
        )}
      </Flex>
      <InView threshold={0.5}>
        {({ ref, inView }) => (
          <Box
            ref={ref}
            sx={{
              flex: ["1 1 100%", "0 1 50%", null, null, null],
              pl: 14,
              py: [10, 28, null, null, null],
            }}
          >
            {subHeading && (
              <Heading as="h2" variant="bodyTitleThin" sx={{ mb: 5 }}>
                {subHeading}
              </Heading>
            )}

            {heading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 1 }}
              >
                <Heading as="h1">{heading}</Heading>
              </motion.div>
            )}

            {content && (
              <motion.div
                sx={{
                  m: 0,
                  px: 5,
                  my: 5,
                  mb: 7,
                }}
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 1 }}
              >
                <MdContent source={content} />
              </motion.div>
            )}

            {buttonText && buttonUrl && (
              <AguilaButton
                text={buttonText}
                url={buttonUrl}
                variant={buttonVariant}
              />
            )}
          </Box>
        )}
      </InView>
    </Flex>
  )
}

export default ImageTextSplit
