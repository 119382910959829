/** @jsxRuntime classic */
/** @jsx jsx */
import { Flex, Box, Heading, jsx } from "theme-ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { createRef, MouseEvent, useLayoutEffect, useState } from "react"
import { motion, useMotionValue, useTransform, useSpring } from "framer-motion"
import useMediaQuery  from "hooks/useMediaQuery"

interface ImageOffsetHoverProps {
  image?: IGatsbyImageData
  imageAlt: string
  normalImage?: string
  reverse?: boolean
  text: string
  textPosition: string
}

const ImageOffsetHover = ({
  image,
  imageAlt,
  normalImage,
  reverse = false,
  text,
  textPosition = "bottom",
}: ImageOffsetHoverProps) => {
  const containerRef = createRef<HTMLDivElement>()
  const matches = useMediaQuery("(min-width: 768px)")
  const [containerHeight, setContainerHeight] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const translateX = useSpring(
    useTransform(x, [0, containerWidth], [20, -20]),
    { damping: 30, stiffness: 200 }
  )
  const translateY = useSpring(
    useTransform(y, [0, containerHeight], [20, -20]),
    { damping: 30, stiffness: 200 }
  )

  const fgTranslateX = useSpring(
    useTransform(translateX, latest => latest * 2),
    { damping: 30, stiffness: 200 }
  )
  const fgTranslateY = useSpring(
    useTransform(translateY, latest => latest * 2),
    { damping: 30, stiffness: 200 }
  )
  useLayoutEffect(() => {
    // Get the distance from the start of the page to the element start
    if (!containerRef.current) {
      return
    }
    const rect = containerRef.current.getBoundingClientRect()
    setContainerHeight(rect.height)
    setContainerWidth(rect.width)
    x.set(rect.width / 2)
    y.set(rect.height / 2)
  })

  function handleMouse(event: MouseEvent) {
    const rect = event.currentTarget.getBoundingClientRect()
    x.set(event.clientX - rect.left)
    y.set(event.clientY - rect.top)
  }

  const AnimatedBox = motion(Flex)
  const ImageBox = motion(Box)
  const DropshadowBox = motion(Box)


  return (
    <AnimatedBox
      sx={{
        justifyContent: "center",
        py: 16,
        position: "relative",
        zIndex: 1,
      }}
      ref={containerRef}
      onMouseMove={handleMouse}
      onMouseLeave={() => {
        x.set(containerWidth / 2)
        y.set(containerHeight / 2)
      }}
    >
      <Flex
        sx={{
          // backgroundColor: "#fff",
          isolation: "isolate",
          flexDirection: !reverse ? "row" : "row-reverse",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            mt: 8,
            mb: 8,
            alignSelf: textPosition === "bottom" ? "flex-end" : "flex-start",
          }}
        >
          <Heading
            variant="hero"
            sx={{
              maxWidth: "570px",
              position: "relative",
              mixBlendMode: "difference",
              color: "white",
              zIndex: 3,
              "@media (max-width: 610px)": {
                fontSize: "3",
                maxWidth: "250px",
              },
            }}
          >
            {text}
          </Heading>
        </Box>
        <ImageBox
          sx={{
            height: "clamp(300px, 40vw, 590px)",
            width: ["310px", "400px", null, null, "700px"],
            position: "relative",
            marginLeft: !reverse ? "-300px" : "auto",
            marginRight: !reverse ? "auto" : "-300px",
            "@media (max-width: 610px)": {
              marginLeft: !reverse ? "-150px" : "auto",
              marginRight: !reverse ? "auto" : "-150px",
            },
            "& .card-image": {
              height: "100%",
              width: "100%",
              objectFit: "cover",
            },
            ":after": {
              content: '""',
              display: "block",
              position: "absolute",
              background: "rgba(0,0,0,0.5)",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 1,
            },
          }}
          style={{ x: fgTranslateX, y: fgTranslateY  }}
        >
          {!normalImage && image && (
            <GatsbyImage className="card-image" image={image} alt={imageAlt} />
          )}

          {normalImage && !image && (
            <img className="card-image" src={normalImage} alt={imageAlt} />
          )}

          <DropshadowBox
            
            sx={{
              width: "100%",
              height: "100%",
              top: "4%",
              left: !reverse ? "4%" : "unset",
              right: !reverse ? "unset" : "4%",
              position: "absolute",
              backgroundColor: "primary",
              zIndex: "-1",
            }}
              style={matches ? { x: translateX, y: translateY } : {}}
          />
        </ImageBox>
      </Flex>
    </AnimatedBox>
  )
}

export default ImageOffsetHover
