/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import { jsx, Flex, Container, Heading, Box } from "theme-ui"

import { getRemoteImage, Image } from "helpers/index"

import AguilaButton from "components/AguilaButton"
import BgImage from "components/BgImage"
import ImageOffsetHover from "components/ImageOffsetHover"
import ImageTextSplit from "components/ImageTextSplit"
import LinksBlock from "components/LinksBlock"
import MdContent from "components/common/MdContent"
import SectionHeading from "components/SectionHeading"

interface HomepageBlockMapperProps {
  block: any
  images: [Image]
}

const HomepageBlockMapper = ({ block, images }: HomepageBlockMapperProps) => {
  switch (block.strapi_component) {
    case "homepage.full-width-background-image-with-content":
      return (
        <BgImage
          overlayGradient={[
            "linear-gradient(274.91deg, rgba(0, 0, 0, 0.7) 99%, rgba(0, 0, 0, 0) 99.63%)",
            null,
            "linear-gradient(274.91deg, rgba(0, 0, 0, 0.4) 31.57%, rgba(0, 0, 0, 0) 99.63%)",
            null,
            null,
            null
          ]}
          alt={block.background_image.alternativeText}
          image={getRemoteImage(block.background_image, images)}
          sx={{ minHeight: ["300px", null, null, "580px", null], py: 24 }}
        >
          <Container>
            <Flex sx={{ justifyContent: "flex-end", width: "100%" }}>
              <Box sx={{ maxWidth: "550px", color: "textWhite" }}>
                <Heading
                  as="h1"
                  sx={{
                    color: "textWhite",
                    maxWidth: ["50%", "40%", null, "100%", null],
                    mb: 5,
                  }}
                >
                  {block.title}
                </Heading>
                <MdContent
                  source={block.content}
                  sx={{
                    p: {
                      variant: "text.subtitle",
                      lineHeight: 1.2,
                      a: {
                        fontSize: "inherit",
                        textShadow: ["1px 1px 2px #000000", null, "none", null, null],
                      },
                    },
                    mb: 5,
                  }}
                />
                <AguilaButton
                  text={block.link_button.link_button_text}
                  url={block.link_button.link_button_url}
                  sx={{ color: "textWhite" }}
                />
              </Box>
            </Flex>
          </Container>
        </BgImage>
      )
    case "homepage.content-with-link-list-and-link-button":
      const linkListData = block.link_list.map((item: any) => ({
        text: item.link_text,
        url: item.link_url,
      }))
      return (
        <Box as="section">
          <Box sx={{ my: 20 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container>
              <Flex
                sx={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 10,
                }}
              >
                <Box sx={{ width: "600px" }}>
                  <MdContent source={block.content} sx={{ mb: 9 }} />
                  <AguilaButton
                    text={block.link_button.link_button_text}
                    url={block.link_button.link_button_url}
                  />
                </Box>
                <LinksBlock heading={block.subtitle} links={linkListData} />
              </Flex>
            </Container>
          </Box>
        </Box>
      )
    case "homepage.event":
      return (
        <ImageTextSplit
          fullImage
          image={getRemoteImage(block.image, images)}
          heading={block.title}
          content={block.content}
          subHeading={block.subtitle}
          buttonText={block.link_button.link_button_text}
          buttonUrl={block.link_button.link_button_url}
          imageAlt={block.image.alternativeText}
        />
      )
    case "homepage.industry-highlight":
      return (
        <ImageTextSplit
          fullImage
          image={getRemoteImage(block.image, images)}
          heading={block.title}
          content={block.content}
          subHeading={block.subtitle}
          buttonText={block.link_button.link_button_text}
          buttonUrl={block.link_button.link_button_url}
          imageAlt={block.image.alternativeText}
        />
      )
    case "homepage.featured-person":
      return (
        <ImageTextSplit
          fullImage
          image={getRemoteImage(block.image, images)}
          heading={block.title}
          content={block.content}
          subHeading={block.subtitle}
          buttonText={block.link_button.link_button_text}
          buttonUrl={block.link_button.link_button_url}
          imageAlt={block.image.alternativeText}
        />
      )

    case "homepage.featured-product":
      return (
        <ImageTextSplit
          buttonText={block.link_button.link_button_text}
          buttonUrl={block.link_button.link_button_url}
          buttonVariant="secondary"
          content={block.content}
          heading={block.title}
          imageAlt={block.product_image.alternativeText}
          image={getRemoteImage(block.product_image, images)}
          subHeading={block.subtitle}
          sx={{
            "h2": { 
              fontSize: 4, 
              fontWeight: 800, 
              color: "secondary", 
              opacity: 1, 
              width: ["auto", "350px"] 
            }, 
            "h1": { 
              fontSize: 3 
            }
          }}
        />
      )
    case "homepage.hover-gallery":
      return (
        <Box
          as="section"
          sx={{
            position: "relative",
            isolation: "isolate",
            pb: [24, null, 72],
            "&:after": {
              content: "''",
              width: "2px",
              height: "100%",
              backgroundColor: "text",
              position: "absolute",
              top: 0,
              left: "50%",
              zIndex: 0,
            },
          }}
        >
          {block.hover_image.map((item: any, index: number) => (
            <ImageOffsetHover
              key={index}
              reverse={index % 2 !== 0}
              text={item.image_text}
              textPosition={index % 2 === 0 ? "top" : "bottom"}
              image={getRemoteImage(item.image, images)}
              imageAlt={item.image.alternativeText}
            />
          ))}
        </Box>
      )
    default:
      return <></>
  }
}

export default HomepageBlockMapper
